import React from "react"
import Typography from "@material-ui/core/Typography"
import { Container, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  content: {
    textAlign: "left",
  },
})

const LicenseAgreementText = ({content}) => {
  const classes = useStyles()
  return (
    <Container className={classes.content} maxWidth={"md"}>
      {/*<Typography variant={"h3"} component={"h1"}>Software License Agreement: NeverNull® MobileUI Free Edition</Typography>*/}
      <div dangerouslySetInnerHTML={{__html: content}}>

      </div>
    </Container>
  )
}

export default LicenseAgreementText
