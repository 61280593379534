import PropTypes from "prop-types"
import React, { useMemo } from "react"

import { css } from "@emotion/core"
import { useTheme } from "@material-ui/core"


const Section = ({ className, tag, children, colored, largeSpacing = false, style, textAlign = "center", ...props }) => {
  const Tag = tag
  const theme = useTheme()
  const bg = colored ? `linear-gradient(35deg, #8514C1 10%, #6111B1 100%)` : "none"

  const sectionStyle = useMemo(() => css`
    position: relative;
    background: ${bg};
    overflow: hidden;
    text-align: ${textAlign};
    padding-top: ${theme.spacing(largeSpacing ? 8 : 4)}px;
    padding-bottom: ${theme.spacing(largeSpacing ? 8 : 4)}px;

    ${theme.breakpoints.up("md")} {
      padding-top: ${theme.spacing(largeSpacing ? 16 : 8)}px;
      padding-bottom: ${theme.spacing(largeSpacing ? 16 : 8)}px;
    }
  `, [theme, largeSpacing])

  return (
    <Tag {...props} className={className} style={style} css={sectionStyle}>
      {children}
    </Tag>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

Section.defaultProps = {
  tag: "section",
}

export default Section
