import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import LicenseAgreementText from "../components/LicenseAgreementText"
import Seo from "../components/Seo"


const LicenseAgreement = ({}) => {
  return (
    <Layout>
      <Seo title={"License Notice"} />

      <Section>
          <LicenseAgreementText />
      </Section>
    </Layout>
  )
}

export default LicenseAgreement
